import { getInfinityyDesktopUrl } from "./utils/redirect.es6.js"
import { HeaderMenuModel, ModalId } from "./header-menu/header-menu-model.es6.js"
import { HeaderMenuView } from "./header-menu/header-menu-view.es6.js"
import { MapTelemetryModel } from "./models/map-telemetry-model.es6.js"
import { PipsModel } from "./pips/pips-model.es6.js"
import { ViewerUrl } from './viewer/viewer-url.es13.js'
import { PipModel } from "./pips/pip-model.es6.js"
import { ParticipantState } from "./pips/participant-state.es6.js"
import { ChatroomUtils } from "./call/chatroom-utils.es6.js"
import { ChatroomController, ChatroomControllerStates } from "./call/chatroom-controller.es6.js"
import { ChatroomView } from "./call/chatroom-view.es6.js"
import { default as Cookies } from "js-cookie";
import { getUser, login } from "./api/api.es6.js"
import { doToast, TOAST_TYPES } from "./utils/toastr-wrapper.es6.js"
import { GuideState } from "./telemetry/guide-state.es6.js"
import { ShowContentItemAlgorithm } from './viewer/show-content-item-algorithm.es13.js'
import { ShowContentItemModelMobile } from './viewer/show-content-item-model-mobile.es13.js'
import { setViewWithConversion } from "./telemetry/room.js"
import { ChatController } from "./chat/chat-controller.es6.js"
import { PipWhereController } from "./pip-where-mode/pip-where-controller.es6"
import { PoiSearch } from "./poi-search/poi-search-controller.mjs"
import 'bootstrap'
import React from "react"
import { createRoot } from 'react-dom/client'
import { MainContainer } from "./components/MainContainer.jsx"
import { initializeTelemetryAndViewers, getCurrentTelemetry } from "./telemetry/room.js"
import { AUTOTOUR_STATE, AutoTour } from "./viewer/auto-tour.mjs"
import { TextToSpeechSpeaker, TTS_SOURCES } from "./speech/text-to-speech.mjs"

const cookieDomainArg = window.siteSettings.CookieDomain ? { domain: window.siteSettings.CookieDomain } : {};

class MobileWebController {
	constructor(options) {
		this._initialUrl = options.initialUrl;
		this._url = this._initialUrl.cloneWithoutTemporaryParams();
		this._activityMonitor = options.activityMonitor;
		this._aiService = options.aiService;
		this._api = options.api;
		this.clientInstanceId = options.clientInstanceId;
		this.guideState = new GuideState(this.clientInstanceId);
		this.guideState.onGuideFollowChanged = (gf) => this._onGuideFollowChangedFromTelemetry(gf);
		this.userModel = options.userModel;
		this.userModel.participantId = this.clientInstanceId;
		this.preloadContent = options.preloadContent;
		this.headerMenuModel = new HeaderMenuModel(options.contentModel.contentId);
		this.chatModel = options.chatModel;
		this.contentModel = options.contentModel;
		this.leadModel = options.leadModel;
		this.pipsModel = options.pipsModel;
		this._sessionModel = options.sessionModel;
		this.youPipModel = new PipModel(this.clientInstanceId);
		this.youPipModel.participantState.telemetryConnected = true;
		this.youPipModel.displayName = 'Guest ' + this.clientInstanceId.substring(0, 4);
		this.pipsModel.addPip(this.youPipModel);
		this.pipsWhereController = new PipWhereController();
		this.poiSearchController = null;
		this._initialLoadStages = {
			contentLoaded: false,
			userIdentified: false
		}
		this._updateUserModelFromCookies();
		this.lastClientInstanceCount = 0;
		this._showContentItemAlgorithm = new ShowContentItemAlgorithm(new ShowContentItemModelMobile(this.contentModel));
		this._showContentItemAlgorithm.onShowContentItem((contentPath, contentItemId, doUpdateScene) => {
			const ci = this.contentModel.getContentItem(contentItemId);
			this._activityMonitor.contentChanged(contentPath, ci);

			if (contentItemId) {
				this._updateContentItemView(contentItemId, doUpdateScene);
			}
		}, { synchronous: true });
		this._lastScenePath = null;
		this._viewInitialized = false;

		this.bottomDrawerView = options.bottomDrawerView;
		this.bottomDrawerView.onBuildingSelected = (building) => this._onBuildingSelected(building);
		this.bottomDrawerView.onContactUsClicked = () => this._onContactUsClicked();
		this.bottomDrawerView.onContentSelected = (contentPath) => this._onContentSelected(contentPath);
		this.bottomDrawerView.onContentItemSelected = (contentItem) => this._onContentItemSelected(contentItem);
		this.bottomDrawerView.onListingSelected = (listing) => this._onListingSelected(listing);
		this.bottomDrawerView.onNextButtonClick = (evt) => this._onNextButtonClick(evt);
		this.bottomDrawerView.onPrevButtonClick = (evt) => this._onPrevButtonClick(evt);
		this.bottomDrawerView.onRequestTts = (source, text) => { this._onRequestTts(source, text) };
		this.chatView = options.chatView;
		this.pipsView = options.pipsView;
		this.pipsView.onWhoWhereToggleClick = (evt) => this._handleWhoWhereToggle(evt);
		this._viewerView = options.viewerView;
		this._viewerView.onIdentified = (name) => { return this._onIdentified(name) };
		this._viewerView.onLeadCaptureDialogOpen = () => { return this._activityMonitor.contactFormShown(); };
		this._viewerView.onLeadCaptureDialogSuccess = () => { return this._handleLeadCaptureDialogSuccess(); };
		this.leadCaptureView = options.leadCaptureView;
		this._initializeHeaderMenuView();
		this.onAutoTourChange = () => { };

		this._initializeTts();

		this.initialized = false;

		if (('undefined' !== typeof (EveryScape)) && EveryScape.Callosum) {
			this._setupTextChatHandling();
		} else {
			$('body').one('LOADED:CALLOSUM', () => { this._setupTextChatHandling(); });
		}

		this._initializeUrlMonitor();
	}

	attachTrack(clientInstanceId, track, isAudio) {
		let pipModel = this.pipsModel.getPip(clientInstanceId);
		if (!pipModel) {
			pipModel = new PipModel(clientInstanceId);
			this.pipsModel.addPip(pipModel);
			this.pipsView._addPip(clientInstanceId);
		}
		const pipView = this.pipsView.getPipView(clientInstanceId);
		if (isAudio) {
			pipModel.audioTrack = track;
			pipView.attachAudioTrack();
		} else {
			pipModel.videoTrack = track;
			pipView.attachVideoTrack();
		}
		this.pipsView.updatePip(clientInstanceId);
		return this;
	}

	/**
	 * Retrieve the closest 20 POI results from a map location that match the criteria specified
	 * @param {string} query The query string we're searching for
	 * @param {LatLng|LatLngLiteral} centerPoint The point on which the search should be centered
	 * @returns {[google.maps.places.Place]} An array of Place objects
	 */
	async searchMap(query, centerPoint) {
		this.poiSearchController.pageSize = 20;
		this.poiSearchController.searchCenter = centerPoint;
		return await this.poiSearchController.search(query);
	}

	openDrawerForMapSearch(query) {
		this.bottomDrawerView
			.openLocationTab()
			.openDrawerForMapSearch()
			.onMapSearch(query);
		return this;
	}

	openDrawerForExplore() {
		this.bottomDrawerView
			.openLocationTab()
			.openDrawer()
			.onMapSearch(null);
	}

	openIframeDrawer(src) {
		this.bottomDrawerView
			.openDrawerForIframe(src);
	}

	/**
	 * Handle a potential scene change, or just a periodic indication of the current scene.
	 * @param {object} scene
	 * @returns
	 */
	handleScenePotentiallyChanged(scene) {
		const scenePath = scene?.content?.contentPath;
		if (this._lastScenePath !== scenePath) {
			this._lastScenePath = scenePath;
			this._showContentItemAlgorithm.handleScenePathChanged(scenePath);
		}
		if (scenePath) {
			this._url = this._url.setSceneContentPath(scenePath)
				.setSceneParams(scene?.viewerPosition);
			this._updateLocationBar();
		}
		return this;
	}

	handleFirstUserInteraction(evt) {
		if (!this._sessionModel.userHasInteracted) {
			this._sessionModel.userHasInteracted = true;
			this._activityMonitor.userInteracted(evt.type, evt.target);
		}
	}

	onAiInteraction() {
		if (!this._sessionModel.userHasInteractedWithAi) {
			this._sessionModel.userHasInteractedWithAi = true;
			this._tts.resume();
		}
	}

	initializeInteractionBodyEvents() {
		const events = ['click', 'touchstart', 'keydown'];
		let blurHandler, handler;

		// Once any of these events happens, clean up the event listeners. We only want the first one.
		function cleanup() {
			events.forEach((e) => { document.body.removeEventListener(e, handler, true) });
			window.removeEventListener('blur', blurHandler, true);
		}

		// The events that definitely happen in the current window get a simple handler.
		// Note that we attach using the "capturing" flag to addEventListener, so that
		// we can see events from all targets in the page.
		handler = (evt) => {
			// only respond to user-initiated events
			if (evt?.isTrusted) {
				this.handleFirstUserInteraction(evt);
				cleanup();
			}
		};
		events.forEach((e) => { document.body.addEventListener(e, handler, true) });

		// In order to detect user interactions with content in a cross-site iframe, we need
		// to listen for the blur event on the main window, but test to make sure the newly
		// active element is an iframe.
		blurHandler = () => {
			if (Array.from(document.querySelectorAll('iframe')).includes(document.activeElement)) {
				this.handleFirstUserInteraction({ type: 'focus', target: document.activeElement });
				cleanup();
			};
		}
		window.addEventListener('blur', blurHandler, true);

		// Force focus on the current window, so that if the first interaction is inside an iframe
		// the blur listener will activate
		window.focus();
	}

	initializeViews() {
		this.initializeInteractionBodyEvents()

		if (this.initialized) {
			return;
		}

		if (this._initialUrl.noContact) {
			this._hideCTAButtons();
		}

		this._registerPageCloseHandlers();
		if (this.userModel.authToken) {
			this._lookupUserByAuthToken();
		}

		this.initialized = true;
		this.bottomDrawerView.initializeView();
		this.chatView.initializeView();
		this.headerMenuView.initializeView();
		this.pipsView.initializeView();
		this.leadCaptureView.initializeView();
		this._viewerView.initializeView();

		if (('undefined' !== typeof (google)) && google?.maps?.Map) {
			this._onGoogleMapsLoaded();
		} else {
			$('body').one('LOADED:GOOGLE-MAPS', () => { this._onGoogleMapsLoaded(); });
		}

		if (('undefined' !== typeof (Twilio)) && Twilio.Video) {
			this._setupCallHandling();
		} else {
			$('body').one('LOADED:TWILIO-VIDEO', () => { this._setupCallHandling(); });
		}
		this._$followModal = $('#SelectedPipMenuHolder').dialog({ autoOpen: false, classes: {'ui-dialog': 'follow-modal'} });
		// TODO: this view code should be moved. I don't know where yet.
		$('#GuideGroupAction').unbind('click').click(() => {
			this.guideState.guideAll();
			this._$followModal.dialog('close');
			return false;
		});
		$('#SelectedPipDoneButton').unbind('click').click(() => {
			this._$followModal.dialog('close');
			return false;
		});
		return this;
	}

	recordCallToAction(ctaName) {
		return this;
	}

	/**
	 * Sends the provided message text as a chat message, and opens the chat dialog.
	 * @param {any} messageText
	 */
	sendChatMessageAndShowChat(messageText) {
		this._onChatClicked();
		this.chatController.sendChatMessage(messageText);
		return this;
	}

	setScene(scenePath, sceneParams) {
		setViewWithConversion({ content: { contentPath: scenePath }, viewerPosition: sceneParams });
		return this;
	}

	startAutoTour(lciList, resume) {
		this._autoTour?.end();
		this._autoTour = new AutoTour(lciList);
		this._autoTour.onRequestNavigate = (lciid) => {
			this.setSelectedContentItem(
				`ListingContentItem:${lciid}`,
				{ updateContentArea: true, updateScene: true }
			);
		}
		this._autoTour.onStateChanged = (newState) => {
			this._onAutoTourChange(newState);
		}
		this._onAutoTourChange(this._autoTour.state);

		resume && this._autoTour.resume();
	}

	stopAutoTour() {
		this._autoTour?.end();
		this._autoTour = null;
		this.onAutoTourChange(AUTOTOUR_STATE.FINISHED);
	}

	_onAutoTourChange(newState) {
		switch (newState) {
			case AUTOTOUR_STATE.PAUSED:
				this.pauseTts(TTS_SOURCES.MOVEMENT);
				break;
			case AUTOTOUR_STATE.PLAYING:
				this.resumeTts(TTS_SOURCES.MOVEMENT);
				break;
		}
		this.onAutoTourChange(newState);
	}

	pauseAutoTour() {
		this._autoTour?.pause();
	}

	resumeAutoTour() {
		this._autoTour?.resume();
	}

	toggleAutoTourPlayState() {
		this._autoTour?.togglePlayState();
	}

	hasAutoTour() {
		return !!this._autoTour;
	}

	// TTS
	_initializeTts() {
		this._tts = new TextToSpeechSpeaker({
			aiService: this._aiService,
			audioControlContainer: $('#TtsAudio'),
			enabled: true,
			paused: true
		});

		this._tts.onEnded = (success) => { this._onTtsEnded(success); }
		this._tts.onStarted = () => { this._onTtsStarted(); }
	}

	_onRequestTts(source, text, voice) {
		if (source == TTS_SOURCES.AI_INTRO) {
			this._tts.pause(source);
		}
		this._tts.speak(source, text, voice);
	}

	enableTts(val) {
		return this._tts.enabled = val;
	}

	get ttsEnabled() {
		return this._tts.enabled;
	}

	/**
	 * Pause TTS (declaring the source of the pause)
	 * @param {TTS_SOURCES} source
	 */
	pauseTts(source) {
		this._tts.pause(source);
	}

	/**
	 * Request resume of TTS (declaring the source of the resume)
	 * @param {TTS_SOURCES} source
	 */
	resumeTts(source) {
		this._tts.resume(source);
	}

	_updateContentItemView(contentItemId, doUpdateScene) {
		this.contentModel.selectedContentItemIndex = this.contentModel.contentItems.findIndex((ci) => ci.id === contentItemId);
		const contentItem = this.contentModel.getSelectedContentItem();
		const listingId = contentItem.listingId;
		let doUpdateListing = false;

		if (!this._viewInitialized) {
			doUpdateListing = true;
			this.headerMenuModel.showLoadingAnimation = false;
			this.headerMenuView.update();
			this._viewInitialized = true;
			this.handleContentLoaded();
		}

		if (!doUpdateListing && (this.contentModel.selectedListingId !== listingId)) {
			doUpdateListing = true;
		}
		if (doUpdateListing) {
			this._updateListingView(listingId);
		}

		console.debug('[controller]', `Updating content item view to ${contentItemId}.`, Date.now() * .001);
		this.bottomDrawerView.setSelectedContentItem();

		if (doUpdateScene || ((false !== doUpdateScene) && doUpdateListing && !contentItem.contentPath)) {
			let scenePath = contentItem.contentPath;
			let sceneParams = contentItem.position;
			if (!scenePath) {
				// Potentially the first contentItem in a listing is a url.
				// Find an appropriate scene to load.
				let firstContentItem = this.contentModel.contentItems.find((ci) => (ci.listingId === listingId) && !!ci.contentPath);
				if (firstContentItem) {
					scenePath = firstContentItem.contentPath;
					sceneParams = firstContentItem.position;
				}
				// TODO - There's another case where there just aren't any ListingContentItems (any more). Deal with that later.
				if (!firstContentItem) {
					// Possible the listing has no ListingContentItems (they can be deleted).
					// Just load _some_ content if we haven't loaded the viewer yet.
					firstContentItem = this.contentModel.contentItems.find((ci) => !!ci.contentPath);
					scenePath = firstContentItem.contentPath;
					sceneParams = firstContentItem.position;
				}
			}
			if (scenePath) {
				this.setScene(scenePath, sceneParams);
			}
		}
		return this._updateLocationBar(this.contentModel.getSelectedContentItem());
	}

	setSelectedContentItem(contentItemId, options) {
		const contentItem = this.contentModel.contentItems.find((ci) => ci.id === contentItemId);
		if (contentItem) {
			this._showContentItemAlgorithm.handleContentItemSelected(contentItem.id, contentItem.contentPath, options);
		}
		return this;
	}

	showFollowModal(clientInstanceId, displayName, avatarPhotoUrl) {
		this._$followModal.dialog('open').parent().css({ left: 0, right: 0, position: 'absolute', top: 'calc(min(25vw,96px) + 63px)', height: '170px', width: '100vw', zIndex: 120 });
		this._$followModal.data('clientInstanceId', clientInstanceId);
		$('#SelectedPipName').text(displayName);
		if (avatarPhotoUrl) {
			$('#SelectedPipAvatar').css({ background: `url(${avatarPhotoUrl})` });
		} else {
			$('#SelectedPipAvatar').css({ background: '' });
		}
		$('#SelectedPipFollowButton').unbind('click').click(() => {
			this._$followModal.dialog('close');
			this.guideState.follow(clientInstanceId);
		});
		return this;
	}

	handleReceivedTelemetry(data) {
		const mapTelemetryModel = new MapTelemetryModel(data);
		const newCount = mapTelemetryModel.clientInstanceCount;
		this.updateMapAndFloorplan(mapTelemetryModel);
		const newPipsModel = createPipsModelFromTelemetry(data, this.youPipModel);
		const diff = this.pipsModel.set(newPipsModel);
		if (diff.isDifferent) {
			this.pipsView.update(diff);
		}

		if (window.useWhereMode) {
			diff.removed.forEach(clientInstanceId => this.pipsWhereController.removeUserWhere(clientInstanceId));

			for (const [clientInstanceId, clientInstance] of Object.entries(data.room.client_instances)) {
				if (clientInstanceId != this.clientInstanceId) {
					const contentPath = clientInstance.view?.content?.contentPath || null;
					const heading = clientInstance.view?.content?.heading || 0;
					const position = clientInstance.view?.viewerPosition || [];
					this.pipsWhereController.updateUserWhere(clientInstanceId, contentPath, position, heading);
				}
			}
		}

		if ((this.lastClientInstanceCount <= 1) && (newCount > 1) && this.chatroomController.isDisconnected()) {
			this.chatroomController.connect();
		}
		this.lastClientInstanceCount = newCount;

		this.guideState.processReceivedRoomState(data);
		if (this.guideState.guidingMe) {
			const guider = this.guideState.lastReceivedTelemetry.room.client_instances[this.guideState.guidingMe];
			const targetListingId = (guider.page && guider.page.listing) ? guider.page.listing.Id : null;
			if (targetListingId && (targetListingId !== this.contentModel.selectedListingId)) {
				this._updateListingView(targetListingId);
			}
			setViewWithConversion(this.guideState.lastReceivedTelemetry.room.client_instances[this.guideState.guidingMe].view);
		}

		return this;
	}

	_handleAfterContentOrUserIdentified() {
		if (this.contentModel.isSplashScreenEnabled && !this.leadModel.leadContact?.email) {
			this._viewerView.showSplashLeadCaptureDialog();
		} else if (!this.userModel.isIdentified) {
			this.userModel.displayName = this.leadModel.leadContact.name || this.userModel.getDisplayNameOrDefault();
		}
		if (this.userModel.displayName) {
			this._onIdentified(this.userModel.displayName);
			this.headerMenuView.update();
		}

		if (window.siteSettings.AppCues.AppStartAiFlow && window.contentModel?.useAi) {
			const userId = this._sessionModel.identityId;
			Appcues.identify(userId, {
				userId: userId,
				username: this.userModel.username,
				participantId: this.userModel.participantId,
				displayName: this.userModel.displayName
			});

			let flowShown = window.localStorage && (window.localStorage.getItem('appCuesStartAiFlowShown') === 'true');
			if (!flowShown) {
				Appcues.show(window.siteSettings.AppCues.AppStartAiFlow);
				window.localStorage.setItem('appCuesStartAiFlowShown', 'true');
			}
		}
		
		return this;
	}

	_getDiagnosticState() {
		const telemetry = getCurrentTelemetry();
		const diagnosticState = {
			chatId: window.roomId,
			clientInstanceId: this.clientInstanceId,
			contentPath: telemetry?.content?.contentPath,
			position: telemetry?.viewerPosition ?? [],
			participantId: this.userModel.participantId,
			clientTokenV4: this._sessionModel.clientTokenV4
		}
		return diagnosticState;
	}

	onMainContainerRendered() {
		this.initializeViews();
		initializeTelemetryAndViewers();
		this.bottomDrawerView.setContent(this.contentModel);
		this.headerMenuModel.contentId = this.contentModel.contentId;
		this._setInitialViewAndContentArea();
		this.leadCaptureView.setContentLoaded();
		this._activityMonitor.loadContent();
		this._handleAfterContentOrUserIdentified();
		return this;
	}

	loadContent() {
		this.contentModel.loadContent().then(() => {
			const domNode = document.getElementById('ViewersAndFloatingUI');
			const root = createRoot(domNode);
			root.render(<MainContainer />);
			console.debug(`[load] Content ${contentId.type}:${contentId.id} loaded.`, Date.now() * .001);
		}).catch((error) => {
			// TODO: This would be fatal unless the user is being guided. Need to add retry logic here.
			console.error(`[load] Failed to load content ${contentId.type}:${contentId.id}.`, error, Date.now() * .001);
		});
		return this;
	}

	onLocalAudioMuted(isMuted) {
		this.youPipModel.participantState.audioMuted = isMuted;
		return this;
	}

	updateMapAndFloorplan(mapTelemetryModel) {
		if (this.bottomDrawerView) {
			const fpv = this.bottomDrawerView.floorplanViewer;
			const gmap = window.bottomDrawerMapsViewer;

			const last = fpv?.applicationState?.mapTelemetryModel;
			if (fpv?.applicationState) {
				fpv.applicationState.mapTelemetryModel = mapTelemetryModel;
				if (window.clientInstanceId && !fpv.applicationState.clientInstanceId) {
					fpv.applicationState.clientInstanceId = window.clientInstanceId;
				}
			}

			const changes = mapTelemetryModel.getChangedClientInstanceIds(last);
			for (const c of [...changes.added, ...changes.floorPlanUpdated]) {
				fpv && fpv.updateClient(c.clientInstanceId);
			}
			for (const c of [...changes.added, ...changes.mapsUpdated]) {
				const displayName = c.profile?.name ? c.profile.name : c.clientInstanceId;
				gmap && gmap.setRadarMarker(c.clientInstanceId, displayName, c.location.lat, c.location.lng, c.heading);
			}
			for (const c of changes.removed) {
				fpv && fpv.updateClient(c.clientInstanceId);
				gmap && gmap.removeRadarMarker(c.clientInstanceId);
			}

		}
		return this;
	}

	removeAllTracks() {
		this.pipsModel.pips.forEach((pip) => {
			const pipView = this.pipsView.getPipView(pip.clientInstanceId);
			pip.audioTrack = pip.videoTrack = null;
			pipView.attachVideoTrack().attachAudioTrack();
		});
		return this;
	}

	_getAccessToken(clientInstanceId, roomId) {
		return this._api.getAccessToken(clientInstanceId,
			window.siteSettings.TwilioRoomIdFormat.replace('{0}', roomId));
	}

	_getContentItem(contentItemSpec) {
		return this.contentModel.contentItems.find((ci) => {
			return (ci.contentReferenceId === contentItemSpec.contentReferenceId) && (ci.contentReferenceType === contentItemSpec.contentReferenceType);
		});
	}

	_getContentItemIndex(contentItem) {
		return this.contentModel.contentItems.findIndex((ci) => {
			return (ci.contentReferenceId === contentItem.contentReferenceId) && (ci.contentReferenceType === contentItem.contentReferenceType);
		});
	}

	_handleWhoWhereToggle() {
		if (this.pipsModel.fullsize) {
			this.pipsModel.fullsize = false;
			$('#InnerPipsContainer .pip').addClass('backgrounded');
			$('#ShowWho').removeClass('active');
			$('#ShowWhere').addClass('active');
		} else {
			this.pipsModel.fullsize = true;
			$('#InnerPipsContainer .pip').removeClass('backgrounded');
			$('#ShowWho').addClass('active');
			$('#ShowWhere').removeClass('active');
		}
		return this;
	}

	handleContentLoaded() {
		this._initialLoadStages.contentLoaded = true;
		this._sendEventIfContentVisible();
	}

	_handleLeadCaptureDialogSuccess() {
		this._activityMonitor.contactFormDataSaved();
		this._activityMonitor.leadCaptured();
		const newName = this._sessionModel?.contact?.DisplayName;
		if (newName) {
			this._onIdentified(newName);
			this.headerMenuView.update();
		}
	}

	handleUserIdentifyDialogFinished() {
		this._initialLoadStages.userIdentified = true;
		this._sendEventIfContentVisible();
	}

	_sendEventIfContentVisible() {
		if (this._initialLoadStages.contentLoaded && this._initialLoadStages.userIdentified) {
			this._activityMonitor.contentVisible();
		}
	}

	_hideCTAButtons() {
		$('#ModalContactUs, #ContactUs, #FooterContactUsButton').remove();
		return this;
	}

	_initializeHeaderMenuView() {
		this.headerMenuView = new HeaderMenuView(this.headerMenuModel);
		this.headerMenuView.onChatButtonClicked = () => { return this._onChatClicked(); };
		this.headerMenuView.onForgotPasswordClicked = () => { return this._onForgotPasswordClicked(); };
		this.headerMenuView.onHamburgerClicked = () => { return this._onHamburgerClicked(); };
		this.headerMenuView.onHeaderInviteClicked = () => { return this._onHeaderInviteClicked(); };
		this.headerMenuView.onSaveRoomClicked = () => { return this._onSaveRoomClicked(); };
		this.headerMenuView.onIdentified = (name) => { return this._onIdentified(name); };
		this.headerMenuView.onLogin = (username, password) => { return this._onLogin(username, password); };
		this.headerMenuView.onLoginClicked = () => { return this._onLoginClicked(); };
		this.headerMenuView.onModalCloseClicked = () => { return this._onModalCloseClicked(); };
		this.headerMenuView.onSignupClicked = () => { return this._onSignupClicked(); };
		this.headerMenuView.onIdentifyModalOpened = () => { return this._activityMonitor.contactFormShown(); };
		this.headerMenuView.onIdentifyModalSuccess = () => { return this._activityMonitor.contactFormDataSaved(); };
		this.headerMenuView.onIdentifyModalSkipped = () => { return this._activityMonitor.contactFormDismissed(); };
		return this;
	}

	_initializeUrlMonitor() {
		window.addEventListener('hashchange', () => {
			const newUrl = window.location.href;
			const expectedUrl = this._url?.toString();
			if (expectedUrl !== newUrl) {
				this._url = new ViewerUrl(newUrl);
				if (this._url.sceneContentPath) {
					this._normalizeContentPath(this._url.sceneContentPath)
						.then((contentPath) => {
							this._setView({ contentPath: contentPath, position: this._url.sceneParams });
						});
				}
			}
		});
		return this;
	}

	setNewRoomId(roomId) {
		this._url = this._url.setChatId(roomId);
		return this._updateLocationBar();
	}

	async _normalizeContentPath(contentPath) {
		if (contentPath?.length > 62 && contentPath.startsWith('matterport://')) {
			return await this._translateMatterportContentPath(contentPath);
		}
		return contentPath;
	}

	async _translateMatterportContentPath(contentPath) {
		const modelId = contentPath.substring(19, 30);
		const oldSweepId = contentPath.substring(37);
		try {
			const newSweepId = await this._api.getMapping('matterport', modelId, oldSweepId);
			return `matterport://model/${modelId}/sweep/${newSweepId}`;
		} catch (ex) {
			return null;
		}
	}


	_onBuildingSelected(building) {
		const contentItem = this.contentModel.getDefaultContentItemForBuilding(building.Id);
		this.setSelectedContentItem(contentItem?.id);
		return true;
	}

	_onChatClicked() {
		this.headerMenuModel.modalOpen = ModalId.CHAT;
		this.headerMenuView.update();
		this.chatView.scrollToBottom();
		return true;
	}

	_onContactUsClicked() {
		this.headerMenuModel.modalOpen = ModalId.CONTACT_US;
		this.headerMenuView.update();
		this.recordCallToAction('ContactUs');
		return true;
	}

	_onContentSelected(contentPath) {
		this._setView({ contentPath: contentPath });
		this.bottomDrawerView.minimizeBottomDrawer();
		return true;
	}

	_onContentItemSelected(contentItem) {
		this.setSelectedContentItem(contentItem?.id);
		if (contentItem.url) {
			this.openIframeDrawer(contentItem.url);
		}
		return true;
	}

	_onForgotPasswordClicked() {
		this._redirectToForgotPassword();
		return true;
	}

	_onGoogleMapsLoaded() {
		this.bottomDrawerView.setGoogleMapsLoaded();
		this.poiSearchController = new PoiSearch();
	}

	_onHamburgerClicked() {
		if (this.userModel.isLoggedIn) {
			this._redirectToDashboard();
		} else {
			this.headerMenuModel.hamburgerMenuOpen = !this.headerMenuModel.hamburgerMenuOpen;
			this.headerMenuView.update();
		}
		return true;
	}

	_onHeaderInviteClicked() {
		this.headerMenuModel.modalOpen = ModalId.INVITE;
		this.headerMenuView.update();
		return true;
	}

	_onSaveRoomClicked() {
		this.headerMenuModel.modalOpen = ModalId.SAVEROOM;
		this.headerMenuView.update();
		return true;
	}

	_onIdentified(name) {
		this.userModel.displayName = this.youPipModel.displayName = name;
		this.pipsView.updatePip(this.youPipModel.clientInstanceId);
		Cookies.set('displayName', name, $.extend({}, cookieDomainArg, {expires: 1000}));
		this._onModalCloseClicked();
		this._activityMonitor.updateUser();
		this.handleUserIdentifyDialogFinished();
		return true;
	}

	_onListingSelected(listing) {
		const contentItem = this.contentModel.contentItems.find((ci) => ci.listingId === listing.Id);
		this.setSelectedContentItem(contentItem?.id);
		return true;
	}

	_lookupUserByAuthToken() {
		getUser(this.userModel.authToken).then((data) => {
			this.userModel.avatarPhotoUrl = data.avatarPhotoUrl;
			this.userModel.displayName = data.displayName;
			this.userModel.identityId = data.identityId;
			this.userModel.preferredEmailClient = data.preferredEmailClient;
			this.userModel.username = data.emailAddress; // TODO update this when it's fixed on server side.
			Cookies.set('displayName', data.displayName || this.userModel.username, $.extend({}, cookieDomainArg, { expires: 1000 }));
			Cookies.set('userName', this.userModel.username, $.extend({}, cookieDomainArg, { expires: 1000 }));
			Cookies.set('participantId', this.userModel.username.replace(/[\.@]/g, '-'), $.extend({}, cookieDomainArg, { expires: 1000 }));
			this._updateUserModelFromCookies();
			this.youPipModel.displayName = this.userModel.displayName;
			this.youPipModel.avatarPhotoUrl = this.userModel.avatarPhotoUrl;
			this.pipsView.updatePip(this.clientInstanceId);
			this._activityMonitor.updateUser();
		});
		return this;
	}

	_onGuideFollowChangedFromTelemetry(gf) {
		// TODO move this view logic somewhere else.
		if (!gf.guidingMe && (0 === gf.followingMe.length)) {
			$('#StatusBarMessageAndAction').hide();
			$('#ContactUsHolder,#GuideGroupAction').show();
		} else {
			$('#StatusBarMessageAndAction').show();
			$('#ContactUsHolder,#GuideGroupAction').hide();
			if (gf.guidingMe) {
				const guider = this.guideState.lastReceivedTelemetry.room.client_instances[gf.guidingMe];
				if (guider && guider.profile && guider.profile.name) {
					$('#StatusBarMessage').text(`You are following ${guider.profile.name}`);
				} else {
					$('#StatusBarMessage').text(`You are following`);
				}
			} else {
				if ((gf.followingMe.length + 1) === Object.keys(this.guideState.lastReceivedTelemetry.room.client_instances).length) {
					$('#StatusBarMessage').text(`You are guiding the group`);
				} else {
					$('#StatusBarMessage').text(`You are guiding ${gf.followingMe.length}`);
				}
			}
		}
		return this;
	}

	_onLogin(username, password) {
		login(username, password).then((result) => {
			const authToken = result.authToken;
			Cookies.set(authToken.name, authToken.value, $.extend({}, cookieDomainArg, { expires: authToken.expires }));
			this.userModel.authToken = authToken.value;
			this._lookupUserByAuthToken();
			this.headerMenuModel.modalOpen = ModalId.NONE;
			this.headerMenuView.update();
		}).catch((error) => {
			doToast(TOAST_TYPES.error, error.message);
		});
		return true;
	}

	_onLoginClicked() {
		this.headerMenuModel.modalOpen = ModalId.LOGIN;
		this.headerMenuModel.hamburgerMenuOpen = false;
		$('#ViewerAndBottomDrawer').show();
		this.headerMenuView.update();
		return true;
	}

	_onModalCloseClicked() {
		this.headerMenuModel.modalOpen = ModalId.NONE;
		this.headerMenuView.update();
		return true;
	}

	_onNextPrevButtonClick(_evt, indexDelta) {
		let i = this.contentModel.selectedContentItemIndex + indexDelta;
		if (i >= this.contentModel.contentItems.length) {
			i = 0;
		} else if (i < 0) {
			i = this.contentModel.contentItems.length - 1;
		}
		this.setSelectedContentItem(this.contentModel.contentItems[i].id);
		return true;
	}

	_onNextButtonClick(_evt) {
		this._onNextPrevButtonClick(_evt, 1);
	}

	_onPrevButtonClick(_evt) {
		this._onNextPrevButtonClick(_evt, -1);
	}

	_onPageClosed() {
		this._activityMonitor.closePage();
		if (this.chatroomController && this.chatroomController.isConnected()) {
			this.chatroomController.disconnect();
		}
		return this;
	}

	_onSignupClicked() {
		this._redirectToSignup();
		return true;
	}

	_onTtsStarted() {
		this._autoTour?.waitForTts();
	}

	_onTtsEnded(success) {
		this._autoTour?.resumeFromTts(success);
	}

	_redirectToDashboard() {
		window.open(getInfinityyDesktopUrl('InfinityyDashboard/BrandHome'));
		return this;
	}

	_redirectToForgotPassword() {
		window.open(getInfinityyDesktopUrl('Account/ForgotPassword'));
		return this;
	}

	_redirectToSignup() {
		window.open(getInfinityyDesktopUrl('Account/Register'));
		return this;
	}

	_registerPageCloseHandlers() {
		/*
		 * https://developer.chrome.com/blog/page-lifecycle-api/
		 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
		 * https://developer.mozilla.org/en-US/docs/Web/API/Window/pagehide_event
		 * https://www.igvita.com/2015/11/20/dont-lose-user-and-app-state-use-page-visibility/
		 * Do not return a value from these event handlers or a confirm dialog will be displayed.
		 */
		$(window).bind('pagehide', () => { this._onPageClosed(); }); // Chrome/Firefox/Edge desktop & mobile. Safari mobile.
		$(window).bind('beforeunload', () => { this._onPageClosed(); }); // Safari desktop.
		return this;
	}

	_setInitialViewAndContentArea() {
		const options = {};
		if (this._initialUrl.sceneContentPath) {
			this._normalizeContentPath(this._initialUrl.sceneContentPath)
				.then((contentPath) => {
					this._setView({ contentPath: contentPath, position: this._initialUrl.sceneParams });
					options.updateScene = false;
				});
		}
		let initialContentItem;
		if (this._initialUrl.contentItemId) {
			initialContentItem = this.contentModel.getContentItem(this._initialUrl.contentItemId);
		}
		if (!initialContentItem && this._initialUrl.listingId) {
			initialContentItem = this.contentModel.contentItems
				.find((ci) => ci.listingId == this._initialUrl.listingId);
		}
		if (!initialContentItem) {
			initialContentItem = this.contentModel.contentItems
				.find((ci) => ci.listingId == this.contentModel.displayOrder.ProjectDefaultListingId);
		}
		if (!initialContentItem) {
			initialContentItem = this.contentModel.contentItems[0];
		}
		return this.setSelectedContentItem(initialContentItem?.id, options);
	}

	_setupCallHandling() {
		this.chatroomUtils = new ChatroomUtils(function () { return Twilio.Video.isSupported });
		this.chatroomController = new ChatroomController(this.chatroomUtils,
			(clientInstanceId, chatId) => { return this._getAccessToken(clientInstanceId, chatId); },
			Twilio.Video,
			() => {
				console.warn('In chatroomController.connect(), did not have telemetryClientInstanceId already.');
				return this.clientInstanceId;
			},
			window.roomId);
		this.chatroomController.telemetryClientInstanceId = this.clientInstanceId;
		this.chatroomView = new ChatroomView(this.chatroomController, {}/*EveryScape.SyncV2.UI*/, this.chatroomUtils);

		$("#ChatCallBtn, #MobileChatCallBtn, #MobileCallBtn").click(() => {
			if (this.chatroomController._state === 'connected') {
				this.chatroomController.disconnect();
			} else {
				this.chatroomController.connect();
			}
		});
		$("#YouPipContainer").unbind('click').click(() => {
			if (this.chatroomController.isConnected()) {
				this.chatroomController.updateMediaDeviceSelections();
			} else {
				if (!this.userModel.isLoggedIn) {
					this.headerMenuModel.identifyDialogDisplayName = this.userModel.displayName;
					this.headerMenuModel.modalOpen = ModalId.IDENTIFY;
					this.headerMenuView.update();
				}
			}
			return false;
		});
		return this;
	}

	_setupTextChatHandling() {
		this.chatController = new ChatController(window.roomId,
			window.siteSettings.ChatRoomIdFormat.replace('{0}', window.roomId), this.chatModel, this.chatView,
			{ leadCaptureView: this.leadCaptureView });
		this.chatController.initialize();
		this.chatController.onRecordSendChatMessageActivity = (message) => { this._activityMonitor.sendChatMessage(message); };
	}

	_setView(contentItem) {
		if (contentItem?.contentPath) {
			setViewWithConversion({ content: { contentPath: contentItem.contentPath }, viewerPosition: contentItem.position });
			this._url = this._url.setSceneContentPath(contentItem?.contentPath)
				.setSceneParams(contentItem?.position);
			this._updateLocationBar();
		}
		return this;
	}

	_updateBuildingViewForListing(listing) {
		console.debug('[controller]', `Updating building view to ${listing.PropertyId}.`, Date.now() * .001);
		const building = this.contentModel.buildings.get(listing.PropertyId);
		this.headerMenuModel.setBuilding(building);
		this.headerMenuView.update();
		this.bottomDrawerView.setSelectedBuilding();
		return this;
	}

	_updateListingView(listingId) {
		const listing = this.contentModel.getListing(listingId);
		if (!listing) {
			return this;
		}
		let doUpdateBuilding = (listing.PropertyId != this.contentModel.getListing(this.contentModel.selectedListingId)?.PropertyId);
		this.contentModel.setSelectedListingId(listingId);

		if (doUpdateBuilding) {
			this._updateBuildingViewForListing(listing);
		}

		console.debug('[controller]', `Updating listing view to ${listingId}.`, Date.now() * .001);
		this.bottomDrawerView.setSelectedListing(listing);
		this._activityMonitor.selectListing();
		this._url = this._url.setListingId(listingId);
		return this;
	}

	_updateLocationBar(contentItem) {
		if (contentItem) {
			if ('ListingContentItem' === contentItem?.contentReferenceType) {
				this._url = this._url.setListingContentItemId(contentItem?.contentReferenceId);
			} else if ('ListingUrl' === contentItem?.contentReferenceType) {
				this._url = this._url.setListingUrlId(contentItem?.contentReferenceId);
			}
		}
		const url = this._url.toString();
		if (url !== window.location.href) {
			window.history.replaceState(this._url, '', url);
		}
		return this;
	}

	_updateUserModelFromCookies() {
		this.userModel.authToken = Cookies.get('InfinityySecurityToken');
		this.userModel.displayName = Cookies.get('displayName');
		this.userModel.username = Cookies.get('userName');
		this.userModel.participantId = Cookies.get('participantId');
		if (!this.userModel.participantId) {
			this.userModel.participantId = this.clientInstanceId;
		}
		this.youPipModel.displayName = this.userModel.displayName;
		return this;
	}
}

function createPipsModelFromTelemetry(data, youPipModel) {
	const model = new PipsModel();
	if (data && data.room && data.room.client_instances) {
		for (const id in data.room.client_instances) {
			if (id === youPipModel.clientInstanceId) {
				continue;
			}
			const ci = data.room.client_instances[id];
			const options = {};
			if (ci.profile) {
				options.avatarPhotoUrl = ci.profile.avUrl;
				options.displayName = ci.profile.name;
			}
			if (ci.call_state) {
				options.participantState = ParticipantState.fromCompressedString(ci.call_state);
			}
			const pipModel = new PipModel(id, options);
			model.addPip(pipModel);
		}
	}
	model.addPip(youPipModel); // Don't change youpip based on telemetry.
	return model;
}

export { MobileWebController }
